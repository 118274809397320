import client from 'services/api/client'
import Logger from 'utils/logging'

export interface InsurgencyType {
    solarSystem: {
        id: number;
        name: string;
        occupierFactionId?: number;
        ownerFactionId?: number;
        security: number;
        securityBand: string;
      }
      corruptionDate: string;
      corruptionPercentage: number;
      corruptionState: number;
      suppressionDate: string;
      suppressionPercentage: number;
      suppressionState: number;
}
export interface GlobalInsurgency {
    campaignId: number;
    pirateFaction: {
        id: number
    }
    corruptionThresHold: number;
    endDateTime?: string;
    startDateTime: string;
    state: 'ACTIVE' | 'FORECAST' | 'PIRATE_VICTORY' | 'NO_WINNER' | 'ANTI_PIRATE_VICTORY';
    suppressionThresHold: number;
    originSolarSystem: {
      id: number;
      name: string;
      security: number;
      securityBand: string;
    }
    insurgencies: InsurgencyType[]
}

type InsurgencyData = GlobalInsurgency[]; 

export const getInsurgencyStatus = async (): Promise<InsurgencyData> => {
    const ret = await client({
        url: `/warzone/insurgency`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as InsurgencyData
            if (!data || !Array.isArray(data)) return []
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone',
                'getWarzoneStatus'
            )
            return []
        })
    return ret
}
