import React from 'react'
import { Container } from 'layouts'
import SectionType from 'models/types/ts/sectionType'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import { HeadingMedium, TaglineRegular } from 'layouts/typography'
import AnimatedText from 'layouts/animated-text'
import Navigation from '../navigation'
import Icons from '../icons/SimpleIcons'
import HeroStyles from './Hero.styles'
import Stats from '../stats'
import { InsurgencyStats } from '../insurgency-stats'

interface Props {
    faction: FactionType
    heroSection: SectionType
    statsSection: SectionType
    insurgencyStatsSection: SectionType
}

const Hero = ({ faction, heroSection, statsSection, insurgencyStatsSection }: Props): JSX.Element => {
    const { headline, teaser, imageFile } = heroSection

    const tabletImage = `${imageFile?.url}?w=980&h=1150&fit=crop&f=top`
    const mobileImage = `${imageFile?.url}?w=820&h=1300&fit=fill&f=top`

    const isPirate = faction === 'guristas' || faction === 'angel'

    return (
        <HeroStyles
            background={imageFile?.url}
            backgroundTablet={tabletImage}
            backgroundMobile={mobileImage}
        >
            <div className="background">
                <Container xlWide className="container">
                    <div className="content">
                        <div className="header">
                            <div className="heading">
                                <div className="icon">
                                    <Icons
                                        faction={faction}
                                        color="white"
                                        width={isPirate ? 130 : 105}
                                    />
                                </div>
                                <div className="icon-mobile">
                                    <Icons
                                        faction={faction}
                                        color="white"
                                        width={isPirate ? 100 : 75}
                                    />
                                </div>
                                <div>
                                    <HeadingMedium>
                                        <AnimatedText>{headline}</AnimatedText>
                                    </HeadingMedium>
                                    <div className="tagline">
                                        <TaglineRegular as="h2">
                                            <AnimatedText>{teaser}</AnimatedText>
                                        </TaglineRegular>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navigation-smaller">
                        <Navigation />
                    </div>
                    <div className="sidebar">
                        {statsSection && <Stats faction={faction} section={statsSection} />}
                        {insurgencyStatsSection && <InsurgencyStats faction={faction} section={insurgencyStatsSection} />}
                    </div>
                </Container>
                <Container xlWide className="navigation">
                    {/* <div className="navigation"> */}
                    <Navigation />
                    {/* </div> */}
                </Container>
            </div>
        </HeroStyles>
    )
}

export default Hero
