import styled, { css } from 'styled-components'
import { tablet, mobile, mobileSmall } from 'styles/media-queries'

interface HeroStyleProps {
    children?: React.ReactNode
    background?: string
    backgroundTablet?: string
    backgroundMobile?: string
}

const breakpoint = tablet.down

const HeroStyles = styled.div<HeroStyleProps>`
    .background {
        // images
        --fl-bg: url(${({ background }) => background});
        --fl-bg-mobile: url(${({ backgroundMobile }) => backgroundMobile});

        padding: 200px 0 60px;
        background-image: var(--fl-bg);
        background-position: center top;
        background-repeat: no-repeat;

        ${mobile.down(css`
            background-image: var(--fl-bg-mobile);
            background-size: contain;
        `)};

        ${mobileSmall.down(css`
            padding-top: 100px;
        `)};
    }

    .container {
        display: grid;
        grid-template-columns: 1fr 375px;

        ${breakpoint(css`
            grid-template-columns: 1fr;
        `)};

        .content {
            .header {
                padding-top: 200px;
                padding-bottom: 140px;

                ${tablet.down(css`
                    min-height: unset;
                    padding-bottom: 150px;
                `)};

                ${mobile.down(css`
                    min-height: unset;
                    padding-top: 100px;
                    padding-bottom: 75px;
                `)};

                .heading {
                    display: grid;
                    grid-template-columns: 140px 1fr;
                    justify-content: start;
                    align-items: center;
                    padding-right: 30%;

                    ${tablet.down(css`
                        grid-template-columns: 120px 1fr;
                        padding-right: 0;
                    `)};

                    ${mobile.down(css`
                        grid-template-columns: 1fr;
                    `)}

                    @keyframes fadeInIcon {
                        from {
                            opacity: 0;
                            transform: translateY(50%) translateZ(0);
                        }
                        90% {
                            opacity: 1;
                        }
                        to {
                            opacity: 1;
                            transform: translateZ(0);
                        }
                    }

                    .icon,.icon-mobile {
                        animation-name: fadeInIcon;
                        animation-duration: 0.8s;
                        animation-delay: 0.3s;
                        animation-fill-mode: forwards;
                        opacity: 0;
                    }

                    .icon {
                        display: block;
                        max-height: 130px;
                        ${tablet.down(css`
                            display: none;
                        `)};
                    }
                    .icon-mobile {
                        display: none;

                        ${tablet.down(css`
                            display: block;
                            margin-bottom: 15px;
                        `)};
                    }

                    h1 {
                        max-width: 600px;

                        ${tablet.down(css`
                            max-width: 400px;
                        `)};
                    }
                }

                .tagline {
                    margin-top: 15px;

                    ${mobile.up(css`
                        grid-column: 2;
                    `)};
                }
            }
        }

        .sidebar {
            @keyframes fadeIn {
                from {opacity: 0; transform: translate(0px, 20px);}
                to {opacity: 1; transform: translate(0px, 0px);}
            }

            opacity: 0;
            animation-name: fadeIn;
            animation-delay: 1s;
            animation-duration: 0.8s;
            animation-fill-mode: forwards;

            width: 100%;
            max-width: 650px;
            justify-self: center;
        }
    }

    .navigation {
        text-align: center;
        max-width: 560px;
        margin-top: 0px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        ${breakpoint(css`
            display: none;
        `)};
    }

    .navigation-smaller {
        display: none;

        ${breakpoint(css`
            /* margin: 0 auto; */
            width: 100%;
            /* max-width: 560px; */
            display: flex;
            justify-content: center;
            overflow: hidden;
        `)};
    }
`

export default HeroStyles
