import React from 'react'
import { useSelector } from 'react-redux'
import { getLocationPayload } from 'lib/location/selectors'
import { Link } from 'features'
import { GlobalState } from 'types'
import { groupWith } from 'ramda'
import { FrontlinePage } from 'pages/frontlines/models/frontlines-types'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { Icons } from '../icons'
import frontLinesSitemap from '../../sitemap'
import { NavigationItems, NavigationItem, FactionWarWrapper, VersusIcon } from './Navigation.styles'



interface Props {
    alignment?: 'horizontal' | 'vertical'
}

interface FactionProps extends Props {
    faction: Pick<FrontlinePage, 'slug' | 'icon' | 'color'>
    currentPage: string
}

const FactionNavigation = ({
    faction: { slug, icon, color },
    currentPage,
    alignment,
}: FactionProps): JSX.Element => {
    return (
        <NavigationItem
            key={slug}
            active={currentPage === slug}
            activeColor={ color === '#A2A1A7' ? '#FFF' : color }
        >
            <div className={alignment}>
                <Link path={{ page: 'frontlines', subpage: slug }}>
                    <Icons icon={icon} />
                </Link>
            </div>
        </NavigationItem>
    )
}

const Navigation = ({ alignment }: Props): JSX.Element => {
    const location = useSelector((state: GlobalState) =>
        getLocationPayload(state)
    )
    const factionWars = groupWith(
        (factionA: FrontlinePage, factionB: FrontlinePage) => {
            return factionA.pirate === factionB.pirate
        },
        frontLinesSitemap
    )
    return (
        <NavigationItems>
            {factionWars.map(([faction1, faction2]) => {
                return <li key={faction1.pirate}>
                    <FactionWarWrapper>
                        <FactionNavigation
                            faction={faction1}
                            currentPage={location.subpage}
                            alignment={alignment}
                        />
                        <VersusIcon>VS</VersusIcon>
                        <FactionNavigation
                            faction={faction2}
                            currentPage={location.subpage}
                            alignment={alignment}
                        />
                    </FactionWarWrapper>
                    <FactionNavigation faction={getFaction(faction1.pirate)} currentPage={location.subpage} alignment={alignment} />
                </li>
            })}
        </NavigationItems>
    )
}

Navigation.defaultProps = {
    alignment: 'vertical',
}

export default Navigation
