import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { GlobalInsurgency, InsurgencyType } from 'pages/frontlines/helpers/get-warzone-insurgency-state'
import { TransparentButtonWithTarget } from 'layouts/button-with-target'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { Link } from 'features'
import { mobile } from 'styles/media-queries'
import { Translate } from 'react-localize-redux'
import { useInView } from 'react-intersection-observer'
import { Tooltip, TooltipProps } from '@mantine/core'
import { SecurityStatus } from '@ccpgames/eve-web/components'
import CountDown from '../hero/components/countdown'
import classes from './Insurgency.module.scss'
import { ANGEL_CARTEL_ID } from './utils'


export const InsurgencyTooltip = (props: TooltipProps): JSX.Element => {
    const { children, ...others } = props;
    // eslint-disable-next-line
    return <Tooltip classNames={{tooltip: classes.insurgecnyTooltip}} {...others}>{children}</Tooltip>
}


const InsurgencyCard = styled('div')<{contentOnRightSide: boolean}>`
    margin-top: 30px;
    padding: ${({contentOnRightSide})=> contentOnRightSide ? '30px 40px 20px 20px' : '30px 20px 20px 40px'};
    border: 1px solid #A2A1A766;
    transition: background 0.3s ease-in-out;
    background: linear-gradient(#171A1B, #101010);
    min-height: 250px;
    background-size:1px 200%;
    display: flex;
    flex-direction: column;
    position: relative;
    ${({contentOnRightSide})=> contentOnRightSide ? 'align-items: flex-end;' : ''}

    &:hover {
        background-position: 100%;

        .buttonContainer {
            opacity: 1;
        }
    }
`

const InsurgencyTitle = styled('div')<{fontSize?: number}>`
    z-index: 2;
    height: 32px;
    text-transform: uppercase;
    font-size: ${({fontSize})=> fontSize || 18}px;
    font-family: Shentox;
    color: #FFF;
    font-weight: 600;
    line-height: 23.4px;
    margin-bottom: 3px;

    display: flex;
    align-items: center;
    gap: 5px;
`

const InsurgencySubTitle = styled('div')`
    z-index: 2;
    font-size: 16x;
    color: #B0B0B0;
    font-weight: 400;
    line-height: 26.4px;
    margin-bottom: 20px;
`

const InsurgencyFOB = styled('span')`
    font-weight: 500;
`

export const SuppressionIcon = ({size = 40, color="#B0B0B0"}: {size?: number, color?: string}): JSX.Element => {
    return <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 20C10 15.7607 12.638 12.1375 16.362 10.6824C17.5085 11.051 18.731 11.25 20 11.25C21.269 11.25 22.4915 11.051 23.638 10.6824C27.362 12.1375 30 15.7607 30 20C30 25.5229 25.5228 30 20 30C14.4772 30 10 25.5229 10 20ZM20 14.375C20.0379 14.375 20.0758 14.3754 20.1135 14.3761C20.6272 17.17 22.83 19.3728 25.6238 19.8865C25.6246 19.9242 25.625 19.9621 25.625 20C25.625 20.038 25.6246 20.0758 25.6239 20.1136C22.83 20.6273 20.6272 22.83 20.1135 25.6239C20.0758 25.6246 20.0379 25.625 20 25.625C19.962 25.625 19.9242 25.6246 19.8864 25.6239C19.3727 22.83 17.17 20.6273 14.3761 20.1136C14.3754 20.0758 14.375 20.038 14.375 20C14.375 19.9621 14.3754 19.9242 14.3761 19.8865C17.17 19.3728 19.3727 17.17 19.8864 14.3761C19.9242 14.3754 19.962 14.375 20 14.375Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 20.0001C3.75 20.0001 3.75 20.0001 3.75 20.0001C3.75 28.1823 9.79732 34.9521 17.6663 36.0838L20 33.7501L22.3337 36.0838C30.2027 34.9521 36.25 28.1823 36.25 20.0001L32.25 20.0001C32.25 20.0001 32.25 20.0001 32.25 20.0001C32.25 26.7656 26.7655 32.2501 20 32.2501C13.2345 32.2501 7.75 26.7656 7.75 20.0001C7.75 20.0001 7.75 20.0001 7.75 20.0001H3.75ZM4.34573 15.6251H8.55436C9.54452 13.0362 11.3877 10.8693 13.7402 9.46808C12.5571 8.73278 11.5129 7.79527 10.6557 6.70386C7.63071 8.83365 5.36521 11.9696 4.34573 15.6251ZM31.4456 15.6251H35.6543C34.6348 11.9696 32.3693 8.83365 29.3443 6.70386C28.4871 7.79527 27.4429 8.73278 26.2598 9.46808C28.6123 10.8693 30.4555 13.0362 31.4456 15.6251Z" fill={color}/>
    </svg>
    
}

export const CorruptionIcon = ({size = 40, color="#B0B0B0"}: {size?: number, color?: string}): JSX.Element => {
    return <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12.5" y="15.625" width="15" height="3.75" fill={color}/>
    <path d="M20 10.625L27.5 15V19.375H12.5V15L20 10.625Z" fill={color}/>
    <path d="M18.125 23.75H21.875V27.5H21.25L20 25.625L18.75 27.5H18.125V23.75Z" fill={color}/>
    <rect x="15.625" y="27.5" width="3.125" height="1.25" fill={color}/>
    <path d="M15.625 28.75H17.5L18.125 31.875H17.5L15.625 28.75Z" fill={color}/>
    <path d="M24.375 28.75H22.5L21.875 31.875H22.5L24.375 28.75Z" fill={color}/>
    <rect x="21.25" y="27.5" width="3.125" height="1.25" fill={color}/>
    <path d="M17.5 28.75H18.75L20 28.125L21.25 28.75H22.5L20.625 32.5H19.375L17.5 28.75Z" fill={color}/>
    <path d="M12.5 19.375L18.3333 25H21.6667L27.5 19.375H12.5Z" fill={color}/>
    <path d="M11.875 23.125H12.5V24.375L13.75 25.625V26.875H11.875L11.25 26.25V23.75L11.875 23.125Z" fill={color}/>
    <path d="M28.125 23.125H27.5V24.375L26.25 25.625V26.875H28.125L28.75 26.25V23.75L28.125 23.125Z" fill={color}/>
    <path d="M14.7917 25.625H11.25V26.25L11.875 26.875H13.75L15.625 27.5H16.25L14.7917 25.625Z" fill={color}/>
    <path d="M25.2083 25.625H28.75V26.25L28.125 26.875H26.25L24.375 27.5H23.75L25.2083 25.625Z" fill={color}/>
    <path d="M27.5 32L31.25 30L28.75 35H27.5V32Z" fill={color}/>
    <path d="M32.5 11.25H37.5L32.5 16.25V11.25Z" fill={color}/>
    <path d="M7.5 11.25L7.5 16.25L2.5 11.25L7.5 11.25Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 7.13082L9 13.5475V25.875L7.5 27.1875L10 30V31.6667L5 28.75V11.25L20 2.5L35 11.25V23.0909L31 25.4003V13.5475L20 7.13082Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.5 31.205V34.021L34.9999 29.646V26.8749L27.5 31.205ZM31.2759 26.137L30.9999 26.2335V26.2963L31.2759 26.137Z" fill={color}/>
    </svg>
    
}

export const getVictoryIcon = (state: GlobalInsurgency['state'], corruptionColor = '#8EF30D'): JSX.Element => {
    switch (state) {
        case 'PIRATE_VICTORY':
            return <Translate>
                {({ translate })=> (<InsurgencyTooltip label={translate('insurgency.pirateWon')}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5.9834L15.6585 8.21091V10.0672H8.34143V8.21091L12 5.9834Z" fill={corruptionColor}/>
                        <path d="M10.9026 12.2947H13.0977V14.1509H12.7319L12.0001 13.2228L11.2684 14.1509H10.9026V12.2947Z" fill={corruptionColor}/>
                        <path d="M11.2681 13.7793H9.80469V14.5218H11.2681V13.7793Z" fill={corruptionColor}/>
                        <path d="M9.80469 14.522H10.9022L11.2681 16.007H10.9022L9.80469 14.522Z" fill={corruptionColor}/>
                        <path d="M14.1951 14.522H13.0975L12.7317 16.007H13.0975L14.1951 14.522Z" fill={corruptionColor}/>
                        <path d="M14.1951 13.7793H12.7317V14.5218H14.1951V13.7793Z" fill={corruptionColor}/>
                        <path d="M10.9026 14.5219H11.6343L12.0001 14.1506L12.366 14.5219H13.0977L12.366 16.3781H11.6343L10.9026 14.5219Z" fill={corruptionColor}/>
                        <path d="M8.34143 10.0669L11.187 13.0369H12.813L15.6585 10.0669H8.34143Z" fill={corruptionColor}/>
                        <path d="M16.0242 11.9229H15.6583V12.4797L14.9266 13.2222V13.7791H16.0242L16.3901 13.4079V12.2941L16.0242 11.9229Z" fill={corruptionColor}/>
                        <path d="M7.97559 11.9229H8.34145V12.4797L9.07316 13.2222V13.7791H7.97559L7.60974 13.4079V12.2941L7.97559 11.9229Z" fill={corruptionColor}/>
                        <path d="M14.5609 13.0371H15.6585V13.4084L15.2926 13.7796H14.5609L13.8292 14.1509H13.4634L14.5609 13.0371Z" fill={corruptionColor}/>
                        <path d="M9.43899 13.0371H8.34143V13.4084L8.70728 13.7796H9.43899L10.1707 14.1509H10.5366L9.43899 13.0371Z" fill={corruptionColor}/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.6282 4.09478C20.3198 5.81424 21.3659 8.18791 21.3659 10.8095C21.3659 16.0585 17.1726 20.3136 12 20.3136C6.82738 20.3136 2.63414 16.0585 2.63414 10.8095C2.63414 8.18791 3.68016 5.81424 5.37179 4.09478L5.04881 3.58353C3.7887 4.83194 2.85387 6.41693 2.38548 8.1953L0.408893 7.65788L2.0521 10.546C2.04989 10.6336 2.04878 10.7214 2.04878 10.8095H0L2.32087 13.1646C2.34131 13.2515 2.36285 13.3378 2.38548 13.4238L0.408893 13.9612L3.252 15.6269C3.29385 15.705 3.33673 15.7825 3.38056 15.8594L1.6077 16.8981L4.78222 17.7612C4.84181 17.8249 4.90222 17.8878 4.96342 17.95L3.51472 19.42H6.79858C6.87292 19.4664 6.94792 19.5118 7.02355 19.5562L6 21.3552L9.16975 20.4933C9.69058 20.6498 10.2292 20.7642 10.7819 20.8327L9.74425 21.8856L10.1582 22.3056L11.1919 21.2567C11.3257 21.5752 11.6371 21.7986 12 21.7986C12.3629 21.7986 12.6743 21.5753 12.8081 21.2567L13.8418 22.3056L14.2557 21.8856L13.2181 20.8327C13.7708 20.7642 14.3094 20.6498 14.8302 20.4933L18 21.3552L16.9764 19.5562C17.0521 19.5118 17.1271 19.4664 17.2014 19.42H20.4853L19.0366 17.95C19.0978 17.8878 19.1582 17.8249 19.2178 17.7612L22.3923 16.8981L20.6194 15.8594C20.6633 15.7825 20.7061 15.705 20.748 15.6269L23.5911 13.9612L21.6145 13.4238C21.6372 13.3378 21.6587 13.2515 21.6791 13.1646L24 10.8095H21.9512C21.9512 10.7214 21.9501 10.6336 21.9479 10.546L23.5911 7.65788L21.6145 8.1953C21.1461 6.41693 20.2113 4.83191 18.9512 3.5835L18.6282 4.09478Z" fill={corruptionColor}/>
                        <path d="M3.63556 5.90871L1.60779 4.7207L2.46483 7.96641L3.63556 5.90871Z" fill={corruptionColor}/>
                        <path d="M20.3644 5.90871L22.3922 4.7207L21.5351 7.96641L20.3644 5.90871Z" fill={corruptionColor}/>
                        <path d="M5.17038 3.87909L4.39023 1.30542L3.51471 5.55919L5.17038 3.87909Z" fill={corruptionColor}/>
                        <path d="M18.8298 3.87909L19.6099 1.30542L20.4854 5.55919L18.8298 3.87909Z" fill={corruptionColor}/>
                    </svg>
                </InsurgencyTooltip>)}
            </Translate>
        case 'ANTI_PIRATE_VICTORY':
            return <Translate>
                {({ translate })=> (<InsurgencyTooltip label={translate('insurgency.antiPirateWon')}>
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.46665 12H1.06665L3.18122 14.1146C3.19984 14.1925 3.21947 14.2701 3.24009 14.3472L1.4392 14.8298L4.02957 16.3253C4.06773 16.3955 4.10678 16.4651 4.14672 16.5341L2.53144 17.4667L5.42379 18.2417C5.47809 18.2989 5.53313 18.3553 5.58889 18.4111L4.26895 19.731H7.26091C7.32866 19.7727 7.39699 19.8134 7.4659 19.8533L6.53332 21.4685L9.42131 20.6947C9.89584 20.8352 10.3866 20.938 10.8901 20.9994L9.94476 21.9448L10.3219 22.3219L11.2637 21.3801C11.3856 21.6661 11.6694 21.8667 12 21.8667C12.3306 21.8667 12.6143 21.6661 12.7363 21.3801L13.6781 22.3219L14.0552 21.9448L13.1099 20.9994C13.6134 20.938 14.1041 20.8352 14.5787 20.6947L17.4667 21.4685L16.5341 19.8533C16.603 19.8134 16.6713 19.7727 16.7391 19.731H19.731L18.4111 18.4111C18.4668 18.3553 18.5219 18.2989 18.5762 18.2417L21.4685 17.4667L19.8533 16.5341C19.8932 16.4651 19.9322 16.3955 19.9704 16.3253L22.5608 14.8298L20.7599 14.3472C20.7805 14.2701 20.8001 14.1925 20.8188 14.1146L22.9333 12H20.5333C20.5333 16.7128 16.7128 20.5333 12 20.5333C7.28715 20.5333 3.46665 16.7128 3.46665 12Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.33333 12C7.33333 10.0738 8.50033 8.42014 10.1659 7.70754C10.673 8.09986 11.3092 8.33332 12 8.33332C12.6907 8.33332 13.327 8.09985 13.8341 7.70752C15.4996 8.42011 16.6667 10.0737 16.6667 12C16.6667 14.5773 14.5773 16.6666 12 16.6666C9.42267 16.6666 7.33333 14.5773 7.33333 12ZM12.0183 9.33339C12.172 10.7239 13.2761 11.828 14.6666 11.9817L14.6667 12L14.6666 12.0183C13.2761 12.172 12.172 13.2761 12.0183 14.6666L12 14.6667L11.9817 14.6666C11.8279 13.2761 10.7239 12.1721 9.33341 12.0183L9.33335 12L9.33341 11.9817C10.7239 11.8279 11.8279 10.7239 11.9816 9.33339L12 9.33332L12.0183 9.33339Z" fill="white"/>
                    </svg>
                </InsurgencyTooltip>)}
            </Translate>
        case 'NO_WINNER':
            return <Translate>
                {({ translate })=> (<InsurgencyTooltip label={translate('insurgency.noWinner')}>
                    <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_9581_2131)">
                        <path d="M14.3438 16.1133L3.88672 5.65625C3.01172 6.87891 2.5 8.37891 2.5 10C2.5 14.1406 5.85938 17.5 10 17.5C11.6211 17.5 13.1211 16.9883 14.3438 16.1133ZM16.1133 14.3438C16.9883 13.1211 17.5 11.6211 17.5 10C17.5 5.85938 14.1406 2.5 10 2.5C8.37891 2.5 6.87891 3.01172 5.65625 3.88672L16.1133 14.3438ZM0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_9581_2131">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </InsurgencyTooltip>)}
            </Translate>
        default:
            return null
    } 

}

const ProgressContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
`

const CircleContainer = styled('div')`
    margin-top: 8px;
`

// current can go higher than max which was not expected
const Circle = styled('div')<{type: 'suppression' | 'corruption', belowCurrent: boolean; atCurrent: boolean, index: number, animationDelay: number, children: React.ReactNode}>`

    @keyframes circleFadeIn {
        from {opacity: 0; transform: translate(-36px, 0px);}
        to {opacity: 1; transform: translate(0px, 0px);}
    }

    opacity: 0;
    animation-name: circleFadeIn;
    animation-delay: ${({ index, animationDelay }) => (index * 0.05) + animationDelay}s;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;

    display: flex;
    align-self: center;
    position: relative;
    justify-content: center;
    width: 24px;
    height: 24px;
    
    background-color: ${({ belowCurrent, atCurrent, type }) => {
        if (atCurrent) {
            return type === 'suppression' ? '#F1F1F1' : '#8EF30D'
        } 
        if (belowCurrent) {
            return '#474746'
        }
        return '#101010'
        
    }};
    border-radius: 24px;
    line-height: 24px;
    margin-right: 12px;
    font-size: 15px;
    font-weight: 600;
    color: ${({ belowCurrent, atCurrent }) => belowCurrent && !atCurrent ? '#FFF' : '#474746'}

    ${({ belowCurrent, atCurrent }) => belowCurrent && !atCurrent ?
    `
    &:after {
        content: '';
        width: 12px;
        height: 4px;
        background-color: #474746;
        position: absolute;
        top: 10px;
        left: 24px;
    }` : ''}

    ${({ atCurrent, type }) => atCurrent ?
    `
    box-shadow: 0px 0px 10px 3px ${type === 'suppression' ? '#F1F1F180' : '#8EF30D80'};
    ` : ''}
`

const IconWrapper = styled('div')`
    margin-right: 12px;
`


const PirateImageWrapper = styled('div')<{contentOnRightSide: boolean}>`
    position: absolute;
    height: 100%;
    width: 30vw;
    bottom: 0;
    z-index: 1;
    ${({contentOnRightSide})=> contentOnRightSide ? 'left: 0;' : 'right: 0;'}
    img.pirateLogo {
        position: absolute;
        width: 227px;
        ${({contentOnRightSide})=> contentOnRightSide ? 'left: 0;' : 'right: 0;'}
        bottom: -40px;
    }

    img.pirateAvatar {
        position: absolute;
        ${({contentOnRightSide})=> contentOnRightSide ? 'left: 35px;' : 'right: 35px;'}
        bottom: 0;
    }
`

const ProgressGrid = styled('div')`
    display: grid;
    grid-template-columns: 52px 1fr;
`

const PirateTitle = styled('div')<{contentOnRightSide: boolean}>`
    position: absolute;
    ${({contentOnRightSide})=> contentOnRightSide ? 'left' : 'right'}: 22px;
    top: 30px;

    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    color: rgba(176, 176, 176, 1);
`

const InsurgencyProgressBarWrapper = styled('div')`
    z-index: 2;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 245px);

    ${mobile.down('max-width: 67%')}
`

const ButtonContainer = styled('div')<{contentOnRightSide: boolean}>`
    opacity: 0;
    position: absolute;
    ${({contentOnRightSide})=> contentOnRightSide ? 'left' : 'right'}: 20px;
    bottom: 20px;
    z-index: 4;
    transition: opacity 300ms ease-in-out;

    &::before {
        content: '';
        background-color: #101010;
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
    }
`

const getDateOrInfinity = (date?: string): number => date ? +new Date(date) : Infinity

const TooltipIconContainer = styled('div')`
    display: flex;

    align-items: center;
    gap: 5px;
    margin-bottom: 5px;

    font-family: Shentox;
    color: #B0B0B0;
    font-size: 16px;
    font-weight: 500;
`

const CorruptionColor = styled('span')`
    color: #8EF30D;
`

const SuppressionColor = styled('span')`
    color: #F1F1F1;
`

export const InsurgencyProgressBar = ({state, type, current, max, insurgencies, animationDelay = 0}: {state: GlobalInsurgency['state'], insurgencies: GlobalInsurgency['insurgencies'], type: 'suppression' | 'corruption', current: number, max: number, animationDelay?: number}): JSX.Element => {
    const { ref, inView } = useInView({
        threshold: 0.3,
        triggerOnce: true,
    })
    const iconColor = state === 'FORECAST' ? '#474746' : undefined
    const sortAccessor = `${type}Date`
    const sortedInsurgencies = insurgencies.sort((a,b) => getDateOrInfinity(a[sortAccessor]) - getDateOrInfinity(b[sortAccessor]))

    return <Translate>
        {({ translate })=> (<ProgressGrid ref={ref}>
            <InsurgencyTooltip  label={translate(`insurgency.${type}`)}><IconWrapper>{type === 'suppression' ? <SuppressionIcon color={iconColor} /> : <CorruptionIcon color={iconColor}  />}</IconWrapper></InsurgencyTooltip>
            <ProgressContainer >
                {inView && [...new Array(max)].map((_,i) => {
                    // eslint-disable-next-line
                    const circle = <CircleContainer key={i} >
                        <Circle type={type} index={i} animationDelay={animationDelay} belowCurrent={(i + 2) <= current} atCurrent={i + 1 === Math.min(current, max)}>{i + 1}</Circle>
                    </CircleContainer>
                    if (i < current) {
                        const insurgency = sortedInsurgencies[i]
                        if (insurgency) {
                            const { name, security } = insurgency.solarSystem
                            // eslint-disable-next-line
                            return <InsurgencyTooltip key={i} label={<div>
                                <InsurgencyTitle>{name}<SecurityStatus status={security}>{security.toFixed(1)}</SecurityStatus></InsurgencyTitle>
                                <TooltipIconContainer>
                                    <CorruptionIcon size={20} />
                                    {`${translate('insurgency.corruption')}: `}
                                    <CorruptionColor>{`${insurgency.corruptionState}/5`}</CorruptionColor>
                                </TooltipIconContainer>
                                <TooltipIconContainer>
                                    <SuppressionIcon size={20} />
                                    {`${translate('insurgency.suppression')}: `}
                                    <SuppressionColor>{`${insurgency.suppressionState}/5`}</SuppressionColor>
                                </TooltipIconContainer>

                            </div>}>{circle}</InsurgencyTooltip>
                        }
                    }
                    return <InsurgencyTooltip label={translate('insurgency.insurgencyProgressDescription', {faction: translate(`insurgency.${type === 'corruption' ? 'pirates' : 'antiPirates'}`), solarSystemLeft: max - current})}>{circle}</InsurgencyTooltip>
                })}
                </ProgressContainer>    
        </ProgressGrid>)}
    </Translate> 
}

export const getStatusFromCampaign = (campaign: GlobalInsurgency): {corruptionValue: number, suppressionValue: number} => campaign.insurgencies.reduce((p,v)=> {
    if (v.corruptionPercentage === 100) {
        p.corruptionValue += 1
    }
    if (v.suppressionPercentage === 100) {
        p.suppressionValue += 1
    }
    return p
}, {corruptionValue: 0, suppressionValue: 0}) 

interface Props {
    campaign: GlobalInsurgency
    contentOnRightSide?: boolean
}

export const InsurgencyCardTitle = ({state, startDateTime, fontSize = 18}: Pick<GlobalInsurgency, 'state' | 'startDateTime'> & {fontSize?: number}): JSX.Element => {
    const VictoryIcon = getVictoryIcon(state)

    return  <Translate>
        {({translate})=> (<InsurgencyTitle fontSize={fontSize}>
            {translate(`insurgency.insurgency${state === 'FORECAST' ? 'FORECAST' : 'ACTIVE'}`)}
            {state === 'FORECAST' ? <CountDown onCompleteCallback={()=> <></>} className={classes.timer} date={new Date(+new Date(startDateTime) + 1000 * 60 * 60 * 24)} /> : null}
            {VictoryIcon}
        </InsurgencyTitle>)}
    </Translate>
}

const SystemNameTooltip = ({name, security, insurgency, children}: Pick<GlobalInsurgency['originSolarSystem'], 'name' | 'security'> & {insurgency?: InsurgencyType, children?: ReactNode}): JSX.Element=> {
    return <Translate>
        {({ translate })=> (
        <InsurgencyTooltip label={<div>
            <InsurgencyTitle>{name}<SecurityStatus status={security}>{security.toFixed(1)}</SecurityStatus></InsurgencyTitle>
            <TooltipIconContainer>
                <CorruptionIcon size={20} />
                {`${translate('insurgency.corruption')}: `}
                <CorruptionColor>{`${insurgency?.corruptionState || 0}/5`}</CorruptionColor>
            </TooltipIconContainer>
            <TooltipIconContainer>
                <SuppressionIcon size={20} />
                {`${translate('insurgency.suppression')}: `}
                <SuppressionColor>{`${insurgency?.suppressionState || 0}/5`}</SuppressionColor>
            </TooltipIconContainer>
            </div>}>
                {children}
            </InsurgencyTooltip>)}
    </Translate>
}

export const InsurgencyCardSubTitle = ({name, security, insurgency}: Pick<GlobalInsurgency['originSolarSystem'], 'name' | 'security'> & {insurgency?: InsurgencyType}): JSX.Element => {
    return <Translate>
        {({ translate })=> (
            <InsurgencySubTitle>
                {translate('insurgency.pirateFOBisInSystem', { systemName: <SystemNameTooltip name={name} security={security} insurgency={insurgency}><InsurgencyFOB>{name}</InsurgencyFOB></SystemNameTooltip>})}
            </InsurgencySubTitle>
        )}
    </Translate>
}


export const Insurgency = ({ campaign, contentOnRightSide }: Props): JSX.Element => {
    if (!campaign) return <></>
    const {corruptionValue, suppressionValue} = getStatusFromCampaign(campaign)

    const isAngels = campaign.pirateFaction.id === ANGEL_CARTEL_ID;
    const faction = isAngels ? 'angel' : 'guristas'

    return <Link key={campaign.campaignId} path={{ page: 'frontlines', subpage: faction }}>
        <InsurgencyCard contentOnRightSide={contentOnRightSide}>
            <InsurgencyCardTitle state={campaign.state} startDateTime={campaign.startDateTime} />
            <InsurgencyCardSubTitle name={campaign.originSolarSystem.name} security={campaign.originSolarSystem.security} insurgency={campaign.insurgencies.find(insurgency => insurgency.solarSystem.id === campaign.originSolarSystem.id)} />
            <InsurgencyProgressBarWrapper>
                <InsurgencyProgressBar state={campaign.state} key='suppression' type='suppression' insurgencies={campaign.insurgencies} current={suppressionValue} max={campaign.suppressionThresHold}  />
                <InsurgencyProgressBar state={campaign.state} key='corruption' type='corruption' insurgencies={campaign.insurgencies} current={corruptionValue} max={campaign.corruptionThresHold}  />
            </InsurgencyProgressBarWrapper>
            <ButtonContainer contentOnRightSide={contentOnRightSide} className='buttonContainer'>
                <TransparentButtonWithTarget
                    color={getFaction(faction).color}
                    backgroundColor="transparent"
                >
                    <Translate>{({ translate }) => translate('insurgency.viewReport')}</Translate>
                </TransparentButtonWithTarget>
            </ButtonContainer>
            <PirateImageWrapper contentOnRightSide={contentOnRightSide}>
                <img className='pirateAvatar' alt="" src={`//web.ccpgamescdn.com/aws/eveonline/images/www/${faction}_avatar.png`}/>
                <PirateTitle contentOnRightSide={contentOnRightSide}>{isAngels ? 'Angel Cartel' : 'Guristas'}</PirateTitle>
                <img className='pirateLogo' alt="" src={`//web.ccpgamescdn.com/aws/eveonline/images/www/eve_${faction}.png`} />
            </PirateImageWrapper>
        </InsurgencyCard>
    </Link>
}