import React, { useContext } from 'react'
import SectionType from 'models/types/ts/sectionType'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import { WarzoneInsurgencyContext } from 'pages/frontlines/WarzoneProviders'
import { getStatusFromCampaign, InsurgencyCardSubTitle, InsurgencyCardTitle, InsurgencyProgressBar } from 'pages/www/components/insurgency'
import styled from 'styled-components'
import { LoadingOverlay } from '@mantine/core'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { Translate } from 'utils/localize'
import { getInsurgencyCampaignByFaction } from 'pages/www/components/insurgency/utils'


interface Props {
    faction: FactionType
    section: SectionType
}

const InsurgencyWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const InsurgencyContainer = styled.div`
    background-color: #171B1C;
    padding: 26px 30px;
    position: relative;
    min-width: 100%;
    min-height: 300px;
`

const ProgressBarTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 26.4px;
    color: #B0B0B0;
    margin-top: 20px;
`

export const InsurgencyStats = ({faction}: Props ): JSX.Element => {
    const insurgencyState = useContext(WarzoneInsurgencyContext)
    let content = <Translate>{({ translate }) => (
        <div>
            <InsurgencyCardTitle fontSize={20} state="ACTIVE" startDateTime="" />
            <ProgressBarTitle>{translate('insurgency.failedToFetchData')}</ProgressBarTitle>
        </div>
    )}</Translate>
    if ((insurgencyState.data || []).length) {
        const campaign = getInsurgencyCampaignByFaction(insurgencyState)(faction)

        const {suppressionValue, corruptionValue } = getStatusFromCampaign(campaign)

        content = <Translate>{({ translate }) => (
            <div>
                <InsurgencyCardTitle fontSize={20} state={campaign.state} startDateTime={campaign.startDateTime} />
                <InsurgencyCardSubTitle name={campaign.originSolarSystem.name} security={campaign.originSolarSystem.security} />
                <ProgressBarTitle>{translate('insurgency.suppression')}</ProgressBarTitle>
                <InsurgencyProgressBar state={campaign.state} key='suppression' type='suppression' insurgencies={campaign.insurgencies} animationDelay={1} current={suppressionValue} max={campaign.suppressionThresHold}  />
                <ProgressBarTitle>{translate('insurgency.corruption')}</ProgressBarTitle>
                <InsurgencyProgressBar state={campaign.state} key='corruption' type='corruption' insurgencies={campaign.insurgencies} animationDelay={1} current={corruptionValue} max={campaign.corruptionThresHold}  />
            </div>)}
        </Translate>
    } else if (insurgencyState.loading) {
        content = <LoadingOverlay overlayColor='#171B1C' loaderProps={{color: getFaction(faction).color}} visible  />
    }
    return <InsurgencyWrapper>
        <InsurgencyContainer>
            {content}
        </InsurgencyContainer>
    </InsurgencyWrapper>
}