import { FactionType } from 'pages/frontlines/models/frontlines-types'
import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import { WarzoneInsurgencyContext } from 'pages/frontlines/WarzoneProviders'
import styled from 'styled-components'
import {  InsurgencyType } from 'pages/frontlines/helpers/get-warzone-insurgency-state'
import {
    FactionIdToName,
    GetLogoForFaction,
    SecurityStatus,
    useWarzoneMap,
    WarzoneMapProvider,
} from '@ccpgames/eve-web/components'
import { useFetcherForFaction } from 'pages/frontlines/helpers/get-warzone-status'
import { KnownFactionID } from '@ccpgames/eve-web/components/warzone/types/faction'
import { RingProgress, ScrollArea } from '@mantine/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'
import { CorruptionIcon, SuppressionIcon, InsurgencyTooltip } from 'pages/www/components/insurgency'
import { useTranslation } from 'react-i18next'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { Translate } from 'react-localize-redux'
import CountUp from 'react-countup'
import { getInsurgencyCampaignByFaction } from 'pages/www/components/insurgency/utils'
import classes from './InsurgencyMap.module.scss'


interface Props {
    faction: FactionType
}

const InsurgencyContainer = styled.div`
    background-color: #171B1C;
    padding: 26px 30px;
    position: relative;
    min-width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    
    @media only screen and (max-width: 1200px) {
        flex-direction: row;
    }
`

const SystemStatsContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1200px) {
        flex-direction: row;
        gap: 20px;
    }
`

const InsurgencyTable = styled.table`
    border-collapse: collapse;
    margin-right: 10px;

    > thead > tr {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #171B1C;
    }

    th {
        text-align: left;
        min-width: 100px;
        padding: 0;
        padding-right: 75px;

        word-break: keep-all;

        font-size: 18px;
        font-weight: 600;
        line-height: 50px;
        color: #b0b0b0;

        div {
            display: flex;
            align-items: center;
            gap: 10px; 
        }
    }

    th:first-child {
        padding-right: 0px;
        min-width: 38px;
        cursor: default;
    }

    th:last-child {
        width: 100%;
        padding-right: 0px;
        cursor: default;
    }

    td {
        font-size: 18px;
        font-weight: 400;
        line-height: 39.6px;
    }

    td:first-child {
        padding-left: 10px;
        padding-right: 10px;
        > div {
            display: flex;
            align-items: center;
        }
    }

    td:nth-child(2) {
        > div {
            display: flex;
            align-items: center;
            gap 5px; 
        }
    }

    td:last-child {
        padding-right: 10px;
    }

    tr {
        padding: 0;
        border-bottom: 1px solid #474746;

        transition: background-color 0.3s ease-in-out;
        cursor: pointer;
    }

    tr.selected {
        background-color: #2C2E33; 
    }

    > tbody {
        > tr:hover {
            background-color: #2C2E33; 
        }
    }
`

const StatusBarsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const StatusBar = styled.div<{ percent: number; color: string }>`
    background-color: ${({ color }) => color};
    width: ${({ percent }) => percent}%;
    height: 8px;
`

const SystemDetails = styled.div`
    flex-basis: 25%;
    padding: 0px 70px 50px 40px;
    
    @media only screen and (max-width: 1200px) {
        flex-basis: 100%;
        min-width: 100%;
        padding-left: 0px;
    }
`

const SystemDetailsTitle = styled.div`
    font-size: 26px;
    font-weight: 500;
    line-height: 28.6px;
    letter-spacing: 0.02em;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 29.7px;
    
    color: #B0B0B0;
`

const TitleSemiBold = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 29.7px;
    
    color: #B0B0B0;
`

const FOBIcon = (): JSX.Element => {
    return <Translate>
        {({translate}) => (
            <InsurgencyTooltip label={translate('insurgency.fob')}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 7.5L8 1.5V14.5H6.5L1.5 10.5L3 8.5L7 11.5V7.5H5Z" fill="white"/>
                    <path d="M11 7.5L8 1.5V14.5H9.5L14.5 10.5L13 8.5L9 11.5V7.5H11Z" fill="white"/>
                </svg>
            </InsurgencyTooltip>
        )}
    </Translate>  
}

const getSupressionIconByStage = (stage): JSX.Element  => {
    switch (stage) {
        case 0:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>
        case 1:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M18 25.7998C17.3538 25.931 16.6849 25.9998 16 25.9998C15.3151 25.9998 14.6462 25.931 14 25.7998V27.4998L16 28.9998L18 27.4998V25.7998Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>
        case 2:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10 24.0007V28H11V24.6622C10.6529 24.4614 10.3191 24.2404 10 24.0007ZM13 25.5422V28L16 30L19 28V25.5422C18.053 25.8396 17.0452 26 16 26C14.9548 26 13.947 25.8396 13 25.5422ZM21 24.6622V28H22V24.0007C21.6809 24.2404 21.3471 24.4614 21 24.6622Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>
        case 3:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10 24.0007V28H11V24.6622C10.6529 24.4614 10.3191 24.2404 10 24.0007ZM13 25.5422V28L16 30L19 28V25.5422C18.053 25.8396 17.0452 26 16 26C14.9548 26 13.947 25.8396 13 25.5422ZM21 24.6622V28H22V24.0007C21.6809 24.2404 21.3471 24.4614 21 24.6622Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8249 4.13788C17.2299 4.04709 16.6204 4 16 4C15.3795 4 14.7701 4.04709 14.175 4.13788L14.4792 6.1149C14.9751 6.03924 15.483 6 16 6C16.517 6 17.0249 6.03924 17.5208 6.1149L17.8249 4.13788Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>
        case 4:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M4 16C4 18.8353 4.98333 21.441 6.62757 23.4945C6.2218 22.4067 6 21.2293 6 20C6 18.5778 6.2969 17.2249 6.83209 16H4ZM25.1679 16C25.7031 17.2249 26 18.5778 26 20C26 21.2293 25.7782 22.4067 25.3724 23.4945C27.0167 21.441 28 18.8353 28 16H25.1679Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10 24.0007V28H11V24.6622C10.6529 24.4614 10.3191 24.2404 10 24.0007ZM13 25.5422V28L16 30L19 28V25.5422C18.053 25.8396 17.0452 26 16 26C14.9548 26 13.947 25.8396 13 25.5422ZM21 24.6622V28H22V24.0007C21.6809 24.2404 21.3471 24.4614 21 24.6622Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8249 4.13788C17.2299 4.04709 16.6204 4 16 4C15.3795 4 14.7701 4.04709 14.175 4.13788L14.4792 6.1149C14.9751 6.03924 15.483 6 16 6C16.517 6 17.0249 6.03924 17.5208 6.1149L17.8249 4.13788Z" fill="white"/>
                <rect x="5" y="16" width="2" height="2" fill="white"/>
                <rect x="25" y="16" width="2" height="2" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>
        case 5:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M21 26.0036V27.5703L17.4699 29.9237C24.511 29.1891 30 23.2355 30 16C30 13.1375 29.1409 10.4757 27.6666 8.2583C28.5203 9.9898 29 11.9389 29 14C29 19.4079 25.6979 24.0445 21 26.0036ZM11 26.0036V27.5703L14.5301 29.9237C7.48898 29.1891 2 23.2355 2 16C2 13.1375 2.85906 10.4757 4.33343 8.2583C3.47966 9.98981 3 11.9389 3 14C3 19.4079 6.3021 24.0445 11 26.0036Z" fill="white"/>
                <circle cx="16" cy="16" r="7.5" stroke="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M10 24.0007V28H11V24.6622C10.6529 24.4614 10.3191 24.2404 10 24.0007ZM13 25.5422V26.5L16 28.5L19 26.5V25.5422C18.053 25.8396 17.0452 26 16 26C14.9548 26 13.947 25.8396 13 25.5422ZM21 24.6622V28H22V24.0007C21.6809 24.2404 21.3471 24.4614 21 24.6622Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8247 4.13789C17.2296 4.04709 16.6202 4 15.9997 4C15.3793 4 14.7699 4.04708 14.1748 4.13787L14.479 6.11489C14.9748 6.03924 15.4827 6 15.9997 6C16.5167 6 17.0246 6.03924 17.5205 6.11491L17.8247 4.13789Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.6488 4.81323C10.499 5.26076 9.43531 5.88044 8.48926 6.6406L9.74104 8.2005C10.5294 7.56704 11.4159 7.05064 12.374 6.6777L11.6488 4.81323Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M23.5107 6.64062C22.5647 5.88045 21.5009 5.26077 20.3512 4.81323L19.626 6.6777C20.5841 7.05065 21.4705 7.56705 22.2589 8.20052L23.5107 6.64062Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16 13.3137 13.3137 16 10 16C13.3137 16 16 18.6863 16 22C16 18.6863 18.6863 16 22 16C18.6863 16 16 13.3137 16 10Z" fill="white"/>
            </svg>            
        default:
            return <></>
            
    }
}

const getCorruptionIconByStage = (stage): JSX.Element  => {
    switch (stage) {
        case 0:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
            </svg>
        case 1:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
                <path d="M15 26H17V28L16 29L15 28V26Z" fill="#8EF30D"/>
            </svg>
        case 2:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
                <path d="M17 25H18V28L17 29V25Z" fill="#8EF30D"/>
                <path d="M15 25H14V28L15 29V25Z" fill="#8EF30D"/>
            </svg>

        case 3:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
                <path d="M15 25H17V29L16 30L15 29V25Z" fill="#8EF30D"/>
                <rect x="18" y="25" width="1" height="3" fill="#8EF30D"/>
                <rect width="1" height="3" transform="matrix(-1 0 0 1 14 25)" fill="#8EF30D"/>
                <rect x="14" y="4" width="1" height="2" fill="#8EF30D"/>
                <rect x="17" y="4" width="1" height="2" fill="#8EF30D"/>
            </svg>
        case 4:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
                <path d="M15 25H17V29L16 30L15 29V25Z" fill="#8EF30D"/>
                <rect x="18" y="25" width="1" height="2" fill="#8EF30D"/>
                <rect width="1" height="2" transform="matrix(-1 0 0 1 14 25)" fill="#8EF30D"/>
                <rect x="14" y="4" width="1" height="2" fill="#8EF30D"/>
                <rect x="17" y="4" width="1" height="2" fill="#8EF30D"/>
                <path d="M2.5 12.5L5 15V18H3L2 16C2.06126 14.506 2.17575 13.7432 2.5 12.5Z" fill="#8EF30D"/>
                <path d="M29.5 12.5L27 15V18H29L30 16C29.9387 14.506 29.8242 13.7432 29.5 12.5Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M2 16C2 19.2132 3.08248 22.1735 4.90265 24.5363L5 24.4V18V16H2ZM27 18V16H30C30 19.2132 28.9175 22.1735 27.0973 24.5363L27 24.4V18Z" fill="#8EF30D"/>
            </svg>
        case 5:
            return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19H14L13 21H12V19Z" fill="#8EF30D"/>
                <path d="M20 19H18L19 21H20V19Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M12.7809 18.2809L12 17.5V16.0854C11.8436 16.0301 11.6753 16 11.5 16C10.6716 16 10 16.6716 10 17.5C10 18.3284 10.6716 19 11.5 19H13.5L12.7809 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M19.2191 18.2809L20 17.5V16.0854C20.1564 16.0301 20.3247 16 20.5 16C21.3284 16 22 16.6716 22 17.5C22 18.3284 21.3284 19 20.5 19H18.5L19.2191 18.2809Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M17 19V20H15V19H12V19.8L15.2 23H16.8L20 19.8V19H17Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 11L16 8L11 11V13V14L14 17L15 17L14 19H15L16 17.5L17 19H18L17 17L18 17L21 14V13V11Z" fill="#8EF30D"/>
                <path d="M15 25H17V29L16 30L15 29V25Z" fill="#8EF30D"/>
                <rect x="18" y="25" width="1" height="3" fill="#8EF30D"/>
                <rect width="1" height="3" transform="matrix(-1 0 0 1 14 25)" fill="#8EF30D"/>
                <rect x="18" y="25" width="2" height="1" fill="#8EF30D"/>
                <rect width="2" height="1" transform="matrix(-1 0 0 1 14 25)" fill="#8EF30D"/>
                <rect x="14" y="4" width="1" height="2" fill="#8EF30D"/>
                <rect x="17" y="4" width="1" height="2" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M21 2.91919C23.7567 3.97354 26.0948 5.87425 27.6965 8.30345L25 10.9999L23 8.99993V5.99993L21 3.99993V2.91919Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.47314 8.30345C6.07483 5.87425 8.41295 3.97354 11.1696 2.91919V3.99993L9.16963 5.99993V8.99993L7.16963 10.9999L4.47314 8.30345Z" fill="#8EF30D"/>
                <path d="M2.5 12.5L5 15V18H3L2 16C2.06126 14.506 2.17575 13.7432 2.5 12.5Z" fill="#8EF30D"/>
                <path d="M29.5 12.5L27 15V18H29L30 16C29.9387 14.506 29.8242 13.7432 29.5 12.5Z" fill="#8EF30D"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M2.03516 15C2.01185 15.3303 2 15.6638 2 16C2 21.5544 5.23457 26.3533 9.92281 28.6158L10.607 27.5895C7.26018 25.7032 5 22.1156 5 18C5 17.6629 5.01516 17.3294 5.04484 17H5V15H2.03516ZM26.9552 17H27V15H29.9648C29.9881 15.3303 30 15.6638 30 16C30 21.5544 26.7654 26.3533 22.0772 28.6158L21.393 27.5895C24.7398 25.7032 27 22.1156 27 18C27 17.6629 26.9848 17.3294 26.9552 17Z" fill="#8EF30D"/>
                <path d="M14 4V6H12L14 4Z" fill="#8EF30D"/>
                <path d="M20 6L18 6L18 4L20 6Z" fill="#8EF30D"/>
            </svg> 
        default:
            return <></>
            
    }
}

const SystemStatsWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    gap: 10px;
`

const SystemStatsRightSide = styled.div`
    display: flex;
    flex-direction: column;
`

const StatsTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 21.6px;
    color: #B0B0B0;
`
const StatsStage = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 21.6px;
`
const StatsPercent = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 21.6px;
    color: #B0B0B0;
`

interface InsurgencyRingProgressProps {
    value: number;
    color: string;
    label: JSX.Element;
}

const InsurgencyRingProgress = ({value, color, label}: InsurgencyRingProgressProps): JSX.Element => {
    return <RingProgress classNames={{root: classes.root}} styles={{label: {display: 'flex', justifyContent: 'center'}}} size={72} thickness={3} sections={[{value, color}]} label={label} rootColor="#393939" />
}

const SortingIcon = ({name, active, isAsc}: {name: string, active: string, isAsc: boolean}): JSX.Element => {
    let icon: IconName = 'sort'
    let type: IconPrefix = 'fal'
    if (active === name) {
        type = 'fad'
        if (isAsc) {
            icon = 'sort-up'    
        } else {
            icon = 'sort-down'
        }
    }
    return <FontAwesomeIcon icon={[type, icon]} />
}

const onTableHeadClick = (name, active, isAsc, setIsAsc, setActiveColum): void => {
    if (name === active) {
        setIsAsc(!isAsc);
    } else {
        setActiveColum(name);
    }
}

const InsurgencyDetails = ({ faction }: Props): JSX.Element => {
    const [previousInsurgency, setPreviousInsurgency] = useState<InsurgencyType | null>(null)
    const [selectedInsurgency, setSelectedInsurgency] = useState<InsurgencyType | null>(null)
    const [isAsc, setIsAsc] = useState(true);
    const [activeColumn, setActiveColum] = useState('system')
    const insurgencyState = useContext(WarzoneInsurgencyContext)
    const { t } = useTranslation()
    const [sortedData, setSortedData] = useState<Array<InsurgencyType & {constellation: string; status: string}>>([]);
    const warzoneData = useWarzoneMap()
    const systemsData = warzoneData.mapData?.systems || {}
    const [FOB, setFOB] = useState<number | null>(null);
    useEffect(()=> {
        if ((insurgencyState.data || []).length) {
            const campaign = getInsurgencyCampaignByFaction(insurgencyState)(faction)
            setFOB(campaign.originSolarSystem.id)
            let data: Array<InsurgencyType & {constellation: string; status: string}> = campaign.insurgencies.map(insurgency => {
                return {
                    constellation: systemsData[insurgency.solarSystem.id]?.con?.name || '',
                    status:  warzoneData.adjacencyMap[insurgency.solarSystem.id] || '',
                    ...insurgency
                }
            });
            switch (activeColumn) {
                case 'system':
                    data = data.sort(({ solarSystem: { name: aName} }, { solarSystem: { name: bName }}) => aName.localeCompare(bName))
                    break
                case 'constellation':
                    data = data.sort(({ constellation: aName }, { constellation: bName }) => aName.localeCompare(bName))
                    break
                case 'status':
                    data = data.sort(({ status: aName }, { status: bName }) => aName.localeCompare(bName))
                    break
                case 'corruption':
                    data = data.sort(({ corruptionPercentage: aAmt }, { corruptionPercentage: bAmt }) => aAmt - bAmt)
                    break
                case 'supression':
                    data = data.sort(({ suppressionPercentage: aAmt }, { suppressionPercentage: bAmt }) => aAmt - bAmt)
                    break
                default:
            }
            if (!isAsc) data.reverse()
            setSortedData(data)
        } else {
            setSortedData([]);
        }
    }, [insurgencyState, isAsc, activeColumn, warzoneData])
    return (
        <Translate>
            {({translate})=> (
            <InsurgencyContainer>
                <SystemDetails>
                        {selectedInsurgency ? 
                            <div>
                                <SystemDetailsTitle>{selectedInsurgency.solarSystem.name} <SecurityStatus status={selectedInsurgency.solarSystem.security}><CountUp decimals={1} start={previousInsurgency?.solarSystem?.security || 0} end={selectedInsurgency.solarSystem.security} duration={0.3} /></SecurityStatus></SystemDetailsTitle>
                                <Title />
                                <TitleSemiBold>{translate('insurgency.insurgencyACTIVE')}</TitleSemiBold>
                                <SystemStatsContainer>
                                    <SystemStatsWrapper>
                                        <InsurgencyRingProgress value={selectedInsurgency.corruptionPercentage} color="#8FF50D" label={getCorruptionIconByStage(selectedInsurgency.corruptionState)} />
                                        <SystemStatsRightSide>
                                            <StatsTitle>{translate('insurgency.corruption')}</StatsTitle>
                                            <StatsStage>{translate('insurgency.stageN', {stageNumber: selectedInsurgency.corruptionState})}</StatsStage>
                                            <StatsPercent><CountUp decimals={0} start={previousInsurgency?.corruptionPercentage || 0} end={selectedInsurgency.corruptionPercentage} duration={0.3} />%</StatsPercent>
                                        </SystemStatsRightSide>
                                    </SystemStatsWrapper>
                                    <SystemStatsWrapper>
                                        <InsurgencyRingProgress value={selectedInsurgency.suppressionPercentage} color="#FFF" label={getSupressionIconByStage(selectedInsurgency.suppressionState)} />
                                        <SystemStatsRightSide>
                                            <StatsTitle>{translate('insurgency.suppression')}</StatsTitle>
                                            <StatsStage>{translate('insurgency.stageN', {stageNumber: selectedInsurgency.suppressionState})}</StatsStage>
                                            <StatsPercent><CountUp decimals={0} start={previousInsurgency?.suppressionPercentage || 0} end={selectedInsurgency.suppressionPercentage} duration={0.3} />%</StatsPercent>
                                        </SystemStatsRightSide>
                                    </SystemStatsWrapper>
                                </SystemStatsContainer>
                            </div>
                            : <div>{t('warzone.description.nothingSelected')}</div>}
                </SystemDetails>
                <ScrollArea
                    className={classes.tableDataOuter}
                    type="auto"
                    offsetScrollbars
                    scrollbarSize={12}
                    style={{'--scrollColor': `${getFaction(faction).color  }66`} as CSSProperties}
                    classNames={{ scrollbar: classes.scrollbar, thumb: classes.thumb }}
                >
                    <InsurgencyTable>
                        <thead>
                            <tr>
                                <th/>
                                <th onClick={()=> onTableHeadClick('system', activeColumn, isAsc, setIsAsc, setActiveColum)}><div>{t('warzone.table.name')}<SortingIcon name='system' active={activeColumn} isAsc={isAsc}  /></div></th>
                                <th onClick={()=> onTableHeadClick('constellation', activeColumn, isAsc, setIsAsc, setActiveColum)}><div> {t('warzone.table.constellation')}<SortingIcon name='constellation' active={activeColumn} isAsc={isAsc}  /></div></th>
                                <th onClick={()=> onTableHeadClick('status', activeColumn, isAsc, setIsAsc, setActiveColum)}><div>{t('warzone.table.adjacency')}<SortingIcon name='status' active={activeColumn} isAsc={isAsc}  /></div></th>
                                <th><div>{translate('insurgency.insurgencyACTIVE')}<InsurgencyTooltip label={translate('insurgency.corruption')}><div style={{cursor: 'pointer'}} onClick={()=> onTableHeadClick('corruption', activeColumn, isAsc, setIsAsc, setActiveColum)}><CorruptionIcon color="#8EF30D" size={24} /><SortingIcon name='corruption' active={activeColumn} isAsc={isAsc}  /></div></InsurgencyTooltip><InsurgencyTooltip label={translate('insurgency.suppression')}><div style={{cursor: 'pointer'}} onClick={()=> onTableHeadClick('supression', activeColumn, isAsc, setIsAsc, setActiveColum)}><SuppressionIcon size={24} color="#FFF" /><SortingIcon name='supression' active={activeColumn} isAsc={isAsc}  /></div></InsurgencyTooltip></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((insurgency) => {
                                const occupierFaction = FactionIdToName(insurgency.solarSystem.occupierFactionId as KnownFactionID)
                                let Logo
                                const fobIcon = FOB === insurgency.solarSystem.id ? <FOBIcon /> : null
                                if (occupierFaction) {
                                    Logo = GetLogoForFaction(occupierFaction)
                                }
                                return (
                                    <tr className={insurgency.solarSystem.id === selectedInsurgency?.solarSystem.id ? 'selected' : ''} onClick={()=> {
                                        setPreviousInsurgency(selectedInsurgency)
                                        setSelectedInsurgency(insurgency)
                                    }}>
                                        <td><div>{Logo ? <InsurgencyTooltip label={getFaction(occupierFaction).name}><Logo /></InsurgencyTooltip> : null}</div></td>
                                        <td><div>{insurgency.solarSystem.name}{fobIcon}</div></td>
                                        <td>
                                            {insurgency.constellation}
                                        </td>
                                        <td>
                                            {insurgency.status && t(`warzone.adjacency.${insurgency.status}`)}
                                        </td>
                                        <td>
                                            <StatusBarsWrapper>
                                                <StatusBar
                                                    percent={
                                                        insurgency.corruptionPercentage
                                                    }
                                                    color="#8FF50D"
                                                />
                                                <StatusBar
                                                    percent={
                                                        insurgency.suppressionPercentage
                                                    }
                                                    color="#FFFFFF"
                                                />
                                            </StatusBarsWrapper>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </InsurgencyTable>
                </ScrollArea>
            </InsurgencyContainer>
            )}
        </Translate>
    )
}

export const InsurgencyMap = ({ faction }: Props): JSX.Element  => {
    const mapFetcher = useFetcherForFaction(faction)
    return (
        <div>
            <WarzoneMapProvider updateFunc={mapFetcher}>
                <InsurgencyDetails faction={faction} />
            </WarzoneMapProvider>
        </div>
    )
}
