import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronCircleRight } from '@fortawesome/pro-light-svg-icons/faChevronCircleRight'
import { faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons/faChevronCircleLeft'
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft'
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand'
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars'
import { faSort } from '@fortawesome/pro-light-svg-icons/faSort'
import { faSortUp } from '@fortawesome/pro-light-svg-icons/faSortUp'
import { faSortDown } from '@fortawesome/pro-light-svg-icons/faSortDown'

export default [
    faEye,
    faEyeSlash,
    faChevronRight,
    faChevronCircleRight,
    faChevronCircleLeft,
    faChevronLeft,
    faChevronDown,
    faTimes,
    faTimesCircle,
    faDownload,
    faCopy,
    faLongArrowLeft,
    faLongArrowRight,
    faExclamationTriangle,
    faInfoCircle,
    faEnvelope,
    faExpand,
    faBars,
    faSort,
    faSortUp,
    faSortDown
]
