import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt'
import { faTimesHexagon } from '@fortawesome/pro-solid-svg-icons/faTimesHexagon'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faSort } from '@fortawesome/pro-solid-svg-icons/faSort'
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'



export default [faSearch, faShareAlt, faTimesHexagon, faBars, faSort, faSortUp, faSortDown]
