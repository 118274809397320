import { GlobalInsurgency } from "pages/frontlines/helpers/get-warzone-insurgency-state";
import { FactionType } from "pages/frontlines/models/frontlines-types";
import { InsurgencyState } from "pages/frontlines/WarzoneProviders";

export const ANGEL_CARTEL_ID = 500011
export const GURISTAS_ID = 500010

const pirateFactionToId = {
  angel: ANGEL_CARTEL_ID,
  guristas: GURISTAS_ID
}

export const getInsurgencyCampaignByFaction = (insurgencyState: InsurgencyState)=> (faction: FactionType): GlobalInsurgency => {
    const id = pirateFactionToId[faction]
    return (insurgencyState?.data || []).find(campaign => (campaign?.pirateFaction?.id === id))
}