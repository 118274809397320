import styled, { css, keyframes } from 'styled-components'
import { mobileSmall, mobile } from 'styles/media-queries'

interface NavigationItemProps {
    active: boolean
    activeColor: string
}

const navIn = keyframes`
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`

export const FactionWarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const VersusIcon = styled.div`
    padding: 0 35px;

    ${mobile.down(css`
        padding: 0 15px;
    `)};
`

export const NavigationItems = styled.ul`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    animation: ${navIn} 1s cubic-bezier(0.62, 0.05, 0.01, 0.99) 1s forwards;

    ${mobileSmall.down(css`
        width: 100%;
        grid-gap: 30px;
    `)};
`

export const NavigationItem = styled.li<NavigationItemProps>`
    position: relative;

    svg {
        fill-opacity: ${({ active }) => (active ? '0.9' : '0.6')};

        ${({ active }) =>
            active &&
            css<NavigationItemProps>`
                filter: drop-shadow(
                    0px 0px 20px ${({ activeColor }) => activeColor}
                );
                @media (max-width: 992px) {
                    filter: drop-shadow(
                        0px 0px 5px ${({ activeColor }) => activeColor}
                    );
                }
            `}

        transition: all 0.25s ease;

        ${mobileSmall.down(css`
            width: 48px;
        `)};

        path {
            fill: ${({ active, activeColor }) => active && activeColor};
        }
    }
    /* ${({ active }) =>
        active &&
        `
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: var(--faction-color, #fff);
            box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4), 0px 0px 10px rgba(255, 255, 255, 0.6);
        }
  `} */

    & svg:hover {
        // Glow effect
        filter: drop-shadow(0px 0px 20px ${({ activeColor }) => activeColor});

        @media (max-width: 992px) {
            filter: drop-shadow(
                0px 0px 5px ${({ activeColor }) => activeColor}
            );
        }
    }

    & svg:hover path {
        fill-opacity: 0.9;
        fill: ${({ activeColor }) => activeColor};
    }
`
