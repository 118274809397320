// eslint-disable
import React, { useContext } from 'react'
import SectionType from 'models/types/ts/sectionType'
import BackgroundImage from 'features/background-image'
import { ButtonWithTarget } from 'layouts/button-with-target'
import AnimatedText from 'layouts/animated-text'
import { HeadingMedium, HeadingRegular } from 'layouts/typography'
import ReactMarkdown from 'utils/markdown'
import { Container, Section } from 'layouts'
import { useWarzoneFactionStatus } from '@ccpgames/eve-web/components'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import CustomFactionWarzoneProgressRound from 'pages/frontlines/components/stats/progress'
import ArcGoalsProviders from 'pages/frontlines/ArcGoalsProviders'
import { WarzoneInsurgencyContext } from 'pages/frontlines/WarzoneProviders'
import { Insurgency } from 'pages/www/components/insurgency'
import FrontlineStyles from './Frontline.styles'
import Character from './components/character'
import { getInsurgencyCampaignByFaction } from '../insurgency/utils'

interface Props {
    section: SectionType
    renderBgImg?: boolean
    showButton?: boolean
}

interface Sizes {
    circleSize: number
    iconSize: number
}

const mobileSizes: Sizes = {
    circleSize: 100,
    iconSize: 30,
}

const desktopSizes: Sizes = {
    circleSize: 155,
    iconSize: 60,
}

const Frontline = ({
    section,
    renderBgImg,
    showButton,
}: Props): JSX.Element => {
    const renderProgress = (
        faction1: FactionType,
        faction1Systems: number,
        faction2: FactionType,
        faction2Systems: number,
        extraFactions?: FactionType[]
    ): JSX.Element => {
        return (
            <>
                <div className="progress-normal">
                    <CustomFactionWarzoneProgressRound
                        faction1={faction1}
                        faction1Systems={faction1Systems}
                        faction2={faction2}
                        faction2Systems={faction2Systems}
                        extraFactions={extraFactions}
                        size={desktopSizes.circleSize}
                        iconSize={desktopSizes.iconSize}
                    />
                </div>
                <div className="progress-small">
                    <CustomFactionWarzoneProgressRound
                        faction1={faction1}
                        faction1Systems={faction1Systems}
                        faction2={faction2}
                        faction2Systems={faction2Systems}
                        extraFactions={extraFactions}
                        size={mobileSizes.circleSize}
                        iconSize={mobileSizes.iconSize}
                    />
                </div>
            </>
        )
    }

    const amarrSystems = useWarzoneFactionStatus('amarr')
    const amarrSystemsCount = amarrSystems ? amarrSystems.length : 0
    const minmatarSystem = useWarzoneFactionStatus('minmatar')
    const minmatarSystemsCount = minmatarSystem ? minmatarSystem.length : 0
    const gallenteSystems = useWarzoneFactionStatus('gallente')
    const gallenteSystemsCount = gallenteSystems ? gallenteSystems.length : 0
    const caldariSystems = useWarzoneFactionStatus('caldari')
    const caldariSystemsCount = caldariSystems ? caldariSystems.length : 0
    const insurgencyState = useContext(WarzoneInsurgencyContext)

    const [isTablet, setIsTablet] = React.useState(false);

    React.useEffect(()=> {
        const setTablet = (event): void => {
            setIsTablet(window?.visualViewport?.width <= 1250)   
        };
        window.addEventListener("resize", setTablet)
        return ()=> {
            window.removeEventListener("resize", setTablet)
        }
    }, [])


    if (section) {
        const content = section?.contentCollection?.items

        if (content) {
            // Order can't be changed in Contentful, we can guarantee correct if we look up by some identifier
            const amarrContent = content[0]
            const minmatarContent = content[1]
            const gallenteContent = content[2]
            const caldariContent = content[3]

            return (
                <FrontlineStyles>
                    <Section id={section.identifier}>
                        {/* <BackgroundImage
                            url={renderBgImg ? section.imageFile?.url : null}
                            repeat="no-repeat"
                            size="cover"
                            position="center top"
                        > */}
                        <Container xlWide className="container">
                            <div className="content">
                                <HeadingRegular>
                                    <AnimatedText>
                                        {section.headline}
                                    </AnimatedText>
                                </HeadingRegular>

                                <div className="body">
                                    <ReactMarkdown source={section.body} />
                                </div>
                                {showButton && section?.buttonUrl && (
                                    <div style={{ marginTop: '30px' }}>
                                        <ButtonWithTarget
                                            url={section.buttonUrl}
                                            color="#1A1A1A"
                                            backgroundColor="white"
                                        >
                                            {section.buttonText}
                                        </ButtonWithTarget>
                                    </div>
                                )}
                            </div>
                            <ArcGoalsProviders>
                                <div className="characters">
                                    <div>
                                        <div className="pair">
                                            <div className="progress">
                                                <div className="outer">
                                                    <div className="inner">
                                                        <div className="circle">
                                                            {renderProgress(
                                                                'amarr',
                                                                amarrSystemsCount,
                                                                'minmatar',
                                                                minmatarSystemsCount,
                                                                ['angel']
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Character
                                                faction="amarr"
                                                content={amarrContent}
                                            />
                                            <Character
                                                faction="minmatar"
                                                content={minmatarContent}
                                            />
                                        </div>
                                        <Insurgency campaign={getInsurgencyCampaignByFaction(insurgencyState)('angel')} />
                                    </div>
                                    <div>
                                        <div className="pair">
                                            <Character
                                                faction="gallente"
                                                content={gallenteContent}
                                            />
                                            <Character
                                                faction="caldari"
                                                content={caldariContent}
                                            />
                                            <div className="progress">
                                                <div className="outer">
                                                    <div className="inner">
                                                        <div className="circle">
                                                            {renderProgress(
                                                                'gallente',
                                                                gallenteSystemsCount,
                                                                'caldari',
                                                                caldariSystemsCount,
                                                                ['guristas']
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Insurgency campaign={getInsurgencyCampaignByFaction(insurgencyState)('guristas')} contentOnRightSide={!isTablet} />
                                    </div>
                                </div>
                            </ArcGoalsProviders>
                        </Container>
                        {/* </BackgroundImage> */}
                    </Section>
                </FrontlineStyles>
            )
        }
    }

    return <></>
}

Frontline.defaultProps = {
    renderBgImg: true,
}

export default Frontline
