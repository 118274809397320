import React from 'react'
import SectionType from 'models/types/ts/sectionType'
import { ButtonWithTarget } from 'layouts/button-with-target'
import { FeatureVideo } from 'features'
import Markdown from 'utils/markdown'
import MessageStyles from './Message.styles'

interface Props {
    section: SectionType
    color?: string
    // isVideoMessage?: boolean
}

const Message = ({ section, color }: Props): JSX.Element => {
    if (section) {
        const { body: message, buttonText, buttonUrl } = section
        const imageUrl = section?.imageFile?.url

        return (
            <MessageStyles color={color} image={imageUrl}>
                <div className="message">
                    <div className="video_wrapper">
                        <FeatureVideo
                            image={section?.imageFile?.url}
                            videoId={section?.videoId}
                            playButtonSize="medium"
                            playButtonColor="red"
                            transition={false}
                            wrapperClassNameOverride="video_wrapper"
                        />
                    </div>

                    <div className="right">
                        <div className="text">
                            <Markdown source={message} />
                        </div>
                        {buttonUrl && (
                            <div className="button">
                                <ButtonWithTarget
                                    url={buttonUrl}
                                    color="#1A1A1A"
                                    backgroundColor={color}
                                >
                                    {buttonText}
                                </ButtonWithTarget>
                            </div>
                        )}
                    </div>
                </div>
            </MessageStyles>
        )
    }
    return <></>
}

Message.defaultProps = {
    color: 'white',
}

export default Message
