import client from 'services/api/client'
import axios from 'axios'
import { WarzoneMapData, WarzoneStatusData } from '@ccpgames/eve-web/components'
import { KnownFactionName } from '@ccpgames/eve-web/components/warzone/types/faction'
import { useMemo } from 'react'
import Logger from 'utils/logging'


export const getWarzoneStatus = async (): Promise<WarzoneStatusData> => {
    const ret = await client({
        url: `/warzone/status`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as WarzoneStatusData
            if (!data || !Array.isArray(data)) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone',
                'getWarzoneStatus'
            )
            return null
        })
    return ret
}

export const getAmarrMinmatarWarzoneData = async (): Promise<WarzoneMapData> => {
    const ret = await axios({
        url: `/staticdata/warzone/amarr-minmatar.json`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as WarzoneStatusData
            if (!data) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone',
                'getAmarrMinmatarWarzoneData'
            )
            return null
        })
    return ret
}

export const getCaldariGallenteWarzoneData = async (): Promise<WarzoneMapData> => {
    const ret = await axios({
        url: `/staticdata/warzone/caldari-gallente.json`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as WarzoneStatusData
            if (!data) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone',
                'getCaldariGallenteWarzoneData'
            )
            return null
        })
    return ret
}

export const useFetcherForFaction = (
    faction: KnownFactionName
): ((signal: AbortSignal) => Promise<WarzoneMapData>) => {
    return useMemo(() => {
        switch (faction) {
            case 'amarr':
                return getAmarrMinmatarWarzoneData
            case 'caldari':
                return getCaldariGallenteWarzoneData
            case 'gallente':
                return getCaldariGallenteWarzoneData
            case 'minmatar':
                return getAmarrMinmatarWarzoneData
            case 'angel':
                return getAmarrMinmatarWarzoneData
            case 'guristas':
                return getCaldariGallenteWarzoneData
            default:
                return undefined
        }
    }, [faction])
}
