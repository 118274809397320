import {
    WarzoneI18NProvider,
    WarzoneLeaderboardProvider,
    WarzoneStatusProvider,
} from '@ccpgames/eve-web/components'
import React, { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import { useUpdater } from '@ccpgames/eve-web/hooks/use-updater'
import { getWarzoneStatus } from './helpers/get-warzone-status'
import { getWarzoneLeaderboard } from './helpers/get-warzone-leaderboard'
import { getInsurgencyStatus, GlobalInsurgency } from './helpers/get-warzone-insurgency-state'

interface Props {
    children: React.ReactNode
    updateInterval?: number
}

export interface InsurgencyState {
    loading: boolean;
    data: GlobalInsurgency[]
}

const initialInsurgencyData = [] as GlobalInsurgency[]

const initialInsurgencyState: InsurgencyState = {
    loading: true,
    data: initialInsurgencyData
}

export const WarzoneInsurgencyContext = createContext<InsurgencyState>(initialInsurgencyState)

const InsurgencyProvider = ({
    updateFunc,
    updateInterval,
    children }): JSX.Element  => {

    const [data, , { loading }] = useUpdater(null, updateFunc, updateInterval)

    const value = useMemo(() => {
      return {
        data,
        loading,
      }
    }, [data, loading])
  
    return <WarzoneInsurgencyContext.Provider value={value}>{children}</WarzoneInsurgencyContext.Provider>
}

export default function WarzoneProviders({
    children,
    updateInterval = 30000,
}: Props): JSX.Element {
    const language = useSelector((state: GlobalState) => state.language)

    return (
        <WarzoneI18NProvider language={language}>
            {/* The WarzoneStatusProvider makes the _CURRENT_ status available to all subcomponents */}
            {/* This does _NOT_ contain the current map layout, just the status */}
            <WarzoneStatusProvider
                updateFunc={getWarzoneStatus}
                updateInterval={updateInterval}
            >
                <InsurgencyProvider updateFunc={getInsurgencyStatus} updateInterval={updateInterval}>
                    <WarzoneLeaderboardProvider updateFunc={getWarzoneLeaderboard}>
                        {children}
                    </WarzoneLeaderboardProvider>
                </InsurgencyProvider>
            </WarzoneStatusProvider>
        </WarzoneI18NProvider>
    )
}
