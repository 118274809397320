import React from 'react'
import Countdown, { zeroPad, CountdownTimeDelta } from 'react-countdown'
import { isClient } from 'config/web'
import s from './CountDown.module.scss'

interface Props {
    date: Date
    onCompleteCallback?: ()=> JSX.Element
    className?: string
}

/* eslint-disable jsx-a11y/anchor-is-valid */

const CountDown = ({ date, onCompleteCallback, className }: Props): JSX.Element => {
    const onComplete = onCompleteCallback || ((): JSX.Element => {
        if (isClient) window.location.reload()

        return <></>
    })

    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: CountdownTimeDelta): JSX.Element => {
        return completed ? (
            onComplete()
        ) : (
            <div className={className || s.timer}>
                {zeroPad(hours + (days * 24))}:{zeroPad(minutes)}:
                {zeroPad(seconds)}
            </div>
        )
    }

    return (
        <div className={s.countdown}>
            <Countdown date={date} renderer={renderer} />
        </div>
    )
}

export default CountDown
